import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/Animation/FadeInUp'
import { Button, Props as ButtonProps } from 'app/components/Common/Button'
import { IconBox, Props as IconBoxProps } from 'app/components/Common/IconBox'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'

export interface Props {
  cta?: ButtonProps
  items: IconBoxProps[]
  images?: ImageProps[]
  label?: string
  title?: string
}

export const ServicesMosaic = memo(function ServicesMosaic({
  cta,
  items,
  images,
  label,
  title,
}: Props) {
  if (items.length < 1) {
    return null
  }

  return (
    <Container>
      <Head dial={4} row space="between" wrap>
        {title ? (
          <FadeInUp>
            <Title>{title}</Title>
          </FadeInUp>
        ) : null}

        {label ? (
          <FadeInUp>
            <Label>{label}</Label>
          </FadeInUp>
        ) : null}
      </Head>

      <Wrapper row stretch wrap>
        <LeftSide>
          {items.map((item, index) => (
            <IconBox key={index} {...item} />
          ))}
        </LeftSide>

        <RightSide>
          {images
            ? images.map((item, index) => <Image key={index} {...item} />)
            : null}

          <Buttons dial={5}>
            {cta ? (
              <FadeInUp>
                <CTA {...cta} />
              </FadeInUp>
            ) : null}
          </Buttons>
        </RightSide>
      </Wrapper>
    </Container>
  )
})

const Container = styled.section``

const Head = styled(FlexBox)`
  background: ${({ theme }) => theme.colors.variants.primaryLight};
  color: ${({ theme }) => theme.colors.variants.primaryDark};
  padding: 5.8125rem 4.167vw 4.8125rem;

  @media (max-width: 1199px) {
    display: block;
    padding: 3.125rem 1.875rem;
  }
`

const Title = styled.h2`
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 9.375rem;
  line-height: 6.875rem;

  @media (max-width: 1199px) {
    font-size: 3.125rem;
    line-height: 3.125rem;
  }
`

const Label = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.8125rem;
  font-weight: 500;
  line-height: 0.875rem;
  text-transform: uppercase;
`

const Wrapper = styled(FlexBox)``

const LeftSide = styled.div`
  width: 75%;
  overflow: hidden;

  > div {
    aspect-ratio: 1.95;
    min-height: auto;
    &:nth-of-type(even) {
      > div {
        &:first-of-type {
          order: 2;
        }
      }
    }
  }

  @media (max-width: 1199px) {
    width: 100%;

    > div {
      aspect-ratio: 1.3;
      &:nth-of-type(even) {
        > div {
          &:first-of-type {
            order: 1;
          }
        }
      }
    }
  }
`

const RightSide = styled.div`
  width: 25%;

  picture {
    display: block;
    aspect-ratio: 0.65;
    position: relative;
    &:nth-of-type(2) {
      aspect-ratio: 1.3;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  @media (max-width: 1199px) {
    width: 100%;

    picture {
      display: none;
    }
  }
`

const Buttons = styled(FlexBox)`
  aspect-ratio: 1.3;
  background: ${({ theme }) => theme.colors.variants.primaryLight};

  @media (max-width: 1199px) {
    aspect-ratio: 0;
    padding: 1.875rem;
  }
`

const CTA = styled(Button)``
